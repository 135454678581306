<template>
  <div class="about">
    <h1>This is an about page {{test}}</h1>
    <el-button type="success" @click="showNotification">展示</el-button>
  </div>
</template>
<script>
import { useRoute } from 'vue-router'
import { ElNotification } from 'element-plus'
import axios from 'axios'
export default {
  setup (props, context) {
    const route = useRoute()
    console.log('set up')
    console.log(route.params, route.query)
    const test = '123'

    const showNotification = () => {
      ElNotification({
        title: '你有一条新的消息通知',
        message: '来了来了他来了！'
      })
      axios.get('/api/news').then((res) => {
        console.log('res', res)
      }).catch((err) => {
        console.log('err', err)
      })
    }
    return {
      test,
      showNotification
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
